import { createApp } from 'vue'

// require styles
import App from './App.vue'
const app = createApp(App)
import pinia from './store'
app.use(pinia)

import 'amfe-flexible'

import 'fullpage.js'
import VueFullpage from 'vue-fullpage.js'
app.use(VueFullpage)

// import animated from "animate.css";
// app.use(animated)

import router from './router'
app.use(router)

import globalProperties from '@/util/global.properties'
globalProperties(app)

// 自定义指令
// 加载 svg 图标
import 'virtual:svg-icons-register'

// 全局样式
import '@/assets/styles/globals.scss'
import 'element-plus/theme-chalk/display.css'

import '@/assets/fonts/iconfont.js'
import '@/assets/fonts/iconfont.css'
// import 'animate.css';
import 'wow.js/css/libs/animate.css'

// 语言切换
// import { setupI18n } from '@/locales'
// setupI18n(app)
// import VConsole from 'vconsole'
// var vConsole = new VConsole()

app.mount('#app')
