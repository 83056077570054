import { menuRoutes } from '@/router/routes'
let currentPath = window.location.href.split('/').slice(-1).join('')
const useMenuStore = defineStore(
  // 唯一ID
  'menu',
  {
    state: () => ({
      menus: menuRoutes,
      actived: currentPath ? `/home/${currentPath}` : '/home/index',
      count: 0,
      isScrolling: false,
    }),
  },
)

export default useMenuStore
