<script setup>
import useSettingsStore from '@/store/modules/settings'
// import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// import en from 'element-plus/lib/locale/lang/en'
const settingsStore = useSettingsStore()
// const lang = {
//   zh_CN: zhCn,
//   en: en,
// }
</script>

<template>
  <el-config-provider
    :size="settingsStore.app.elementSize"
    :button="{ autoInsertSpace: true }"
  >
    <!-- <RouterView
      class="m-full"
      :style="{
        '--g-main-sidebar-actual-width': mainSidebarActualWidth,
        '--g-sub-sidebar-actual-width': subSidebarActualWidth,
      }"
    /> -->
    <RouterView class="m-full" />
  </el-config-provider>
</template>
<style></style>
