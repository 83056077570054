<template>
  <div class="header">
    <div class="header-bar">
      <div class="logo">
        <img :src="imgLogo" alt="" />
      </div>
      <div class="display-icon" @click="handleMenu">
        <img :src="displayIcon" alt="" />
      </div>
    </div>
    <div v-show="showMenu" class="header-menu">
      <li v-for="(item, index) in menuList" :key="index">
        <div class="menu-item">
          <div
            v-if="item?.children"
            class="add"
            @click="handleChildrenMenu(item, index)"
          >
            <img v-show="activeSubMenuIndex !== index" :src="addImg" alt="" />
            <img v-show="activeSubMenuIndex == index" :src="reduceImg" alt="" />
          </div>
          <div v-else class="add"></div>
          <div class="menu-content" @click="goPage(item)">
            {{ item.meta.title }}
          </div>
        </div>
        <div
          v-if="item?.children && index === activeSubMenuIndex"
          class="menu-sub-item"
        >
          <div
            v-for="subItem in item?.children"
            :key="subItem"
            class="menu-sub-item-content"
          >
            <span @click="goPage(subItem)">{{ subItem.meta.title }}</span>
          </div>
        </div>
      </li>
    </div>
  </div>
</template>

<script setup>
import imgLogo from '@/assets/images/layout/logo.png'
import addImg from '@/assets/images/layout/add_icon.png'
import reduceImg from '@//assets/images/layout/reduce_icon.png'
import displayIcon from '@/assets/images/layout/navigation.svg'
import { reactive, ref } from 'vue'
import useMenuStore from '@/store/modules/menu'
const router = useRouter()
const menuStore = useMenuStore()
const menuList = menuStore.menus
let showMenu = ref(false)
let activeSubMenuIndex = ref(null)

const handleMenu = () => {
  console.log('点击menu')
  showMenu.value = !showMenu.value
}

const handleChildrenMenu = (item, index) => {
  activeSubMenuIndex.value = activeSubMenuIndex.value === index ? null : index
}

const goPage = (item) => {
  if (item.name == 'try' || item.name == 'develop') {
    return
  }
  if (item.outLink) {
    window.open(item.path, '_blank')
  } else {
    router.push({
      path: item.meta.fullPath,
    })
  }
  showMenu.value = !showMenu.value
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  .header-bar {
    display: flex;
    padding: 0 20px;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 5px 0px rgba(199, 199, 199, 0.3);
    .logo {
      width: 150px;
      height: 30px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .display-icon {
      display: flex;
      justify-content: end;
      // width: 40px;
      img {
        width: 40%;
        object-fit: cover;
      }
    }
  }
  .header-menu {
    font-size: 18px;
    font-weight: 500;
    li {
      min-height: 50px;
      line-height: 50px;
      border-bottom: 1px solid rgba(230, 230, 230, 1);
      display: flex;
      flex-direction: column;
      .menu-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px 0 20px;
        .add {
          img {
            width: 35px;
          }
        }
        .menu-content {
          text-align: end;
        }
      }
      .menu-sub-item {
        background-color: rgba(242, 242, 242, 1);
        padding: 0 20px 0 20px;
        display: flex;
        flex-direction: column;
        .menu-sub-item-content {
          height: 50px;
          line-height: 40px;
          text-align: end;
          span {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
