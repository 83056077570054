<script setup name="Layout">
import Header from './components/Header/index.vue'
import Footer from './components/Footer/index.vue'
import useSettingsStore from '@/store/modules/settings'

const { proxy } = getCurrentInstance()
const routeInfo = useRoute(),
  router = useRouter()

const settingsStore = useSettingsStore()

settingsStore.setMode()
watch(
  () => settingsStore.menu.subMenuCollapse,
  (val) => {
    if (settingsStore.mode === 'mobile') {
      if (!val) {
        document.querySelector('body').classList.add('hidden')
      } else {
        document.querySelector('body').classList.remove('hidden')
      }
    }
  },
)

watch(
  () => routeInfo.path,
  () => {
    if (settingsStore.mode === 'mobile') {
      settingsStore.$patch((state) => {
        state.menu.subMenuCollapse = true
      })
    }
  },
)

onMounted(() => {
  proxy.$hotkeys('f5', (e) => {
    if (settingsStore.topbar.enablePageReload) {
      e.preventDefault()
      reload()
    }
  })
})
provide('reload', reload)
function reload() {
  router.push({
    name: 'reload',
  })
}

provide('switchMenu', switchMenu)
function switchMenu(index) {}
</script>

<template>
  <div class="layout" :data-full="routeInfo.meta.full">
    <div id="app-main">
      <Header />
      <router-view v-slot="{ Component, route: curRoute }">
        <!-- <keep-alive> -->
        <component :is="Component" :key="curRoute.fullPath" />
        <!-- </keep-alive> -->
      </router-view>
      <!-- <Footer /> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
[data-menu-mode='head'] .layout[data-full='true'] {
  .topbar-container {
    display: none;
    opacity: 0;
    width: 100%;
    transform: translateX(-50%) !important;
    transition: width 0s, top 0s, transform 0s, background-color 0s;
  }
}

[data-mode='mobile'] {
  .layout[data-full='true'] {
    .topbar-container {
      display: flex;
      opacity: 1;
    }
  }
}

[data-mode='pc'][data-menu-mode='head'] {
  .layout[data-full='true'] {
    .topbar-container + .main {
      margin: var(--g-header-height) 0 0;
    }
    .topbar-container {
      z-index: -1;
      top: 0 !important;
    }
    .wrapper {
      padding: 0 !important;
    }
  }
}

[data-menu-mode='head'] .layout[data-full='true'],
[data-mode='mobile'] {
  .sidebar-container {
    transform: translateX(
      calc((var(--g-main-sidebar-width) + var(--g-sub-sidebar-width)) * -1)
    );
    &.show {
      transform: translateX(0);
    }
  }
  .main-container {
    margin-left: 0 !important;
  }
  &[data-menu-mode='single'] {
    .sidebar-container {
      transform: translateX(calc(var(--g-sub-sidebar-width) * -1));
      &.show {
        transform: translateX(0);
      }
    }
  }
}
.layout {
  height: 100%;
}
#app-main {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transition: all 0.2s;
}
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: -1px 0 0 0 var(--g-box-shadow-color);
  transition: padding-top 0.3s;
  .sidebar-container {
    position: fixed;
    z-index: 1010;
    top: 0;
    bottom: 0;
    display: flex;
    transition: transform 0.3s, top 0.3s;
    width: calc(
      var(--g-main-sidebar-actual-width) + var(--g-sub-sidebar-actual-width)
    );
  }
  .sidebar-mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 50%);
    backdrop-filter: blur(2px);
    transition: all 0.2s;
    transform: translateZ(0);
    opacity: 0;
    visibility: hidden;
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
  .main-sidebar-container:not(.main-sidebar-leave-active)
    + .sub-sidebar-container {
    left: var(--g-main-sidebar-width);
  }
  .main-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin-left: calc(
      var(--g-main-sidebar-actual-width) + var(--g-sub-sidebar-actual-width)
    );
    background-color: var(--g-main-bg);
    transition: margin-left 0.3s, background-color 0.3s;
    .topbar-container {
      top: 0;
      z-index: 998;
    }
    .main {
      height: 100%;
      flex: auto;
      position: relative;
      overflow: hidden;
      transition: 0.3s;
    }
    .topbar-container + .main {
      margin: var(--g-topbar-height) 0 0;
    }
  }
}
header:not(.header-leave-active) + .wrapper {
  padding-top: var(--g-header-height);
  .sidebar-container {
    top: var(--g-header-height);
    :deep(.sidebar-logo) {
      display: none;
    }
    :deep(.el-menu) {
      padding-top: 0;
    }
  }
  .main-container {
    .topbar-container {
      top: var(--g-header-height);
      :deep(.tools) {
        display: none;
      }
    }
  }
}

// 主内容区动画
.main-enter-active {
  transition: 0.2s;
}
.main-leave-active {
  transition: 0.15s;
}
.main-enter-from {
  opacity: 0;
  margin-left: -20px;
}
.main-leave-to {
  opacity: 0;
  margin-left: 20px;
}
</style>
