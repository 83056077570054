import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'

import '@/assets/styles/nprogress.scss'

// 路由相关数据
import { constantRoutes } from './routes'
let isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
})

router.beforeEach(async (to, from, next) => {
  if (isMobile) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  next()
})

router.afterEach((to, from) => {})

export default router
