<template>
  <div class="header">
    <Logo />
    <Menus></Menus>
    <el-icon
      v-if="settingsStore.mode == 'mobile'"
      class="menuIcon"
      @click="changeCollapse"
    >
      <Menu />
    </el-icon>
  </div>
</template>

<script setup>
import Logo from '../Logo/index.vue'
import Menus from './menus.vue'
import { Menu } from '@element-plus/icons-vue'
import useSettingsStore from '@/store/modules/settings'
const settingsStore = useSettingsStore()
const changeCollapse = () => {
  settingsStore.isCollapse = !settingsStore.isCollapse
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  padding-left: 200px;
  // height: 60px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(199, 199, 199, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  & > .el-menu--horizontal {
    flex: 1;
  }
  .title {
    position: absolute;
    left: 20px;
    top: 5px;
    width: 200px;
  }
  .el-menu-vertical,
  .el-menu-collapse {
    left: -300px;
  }
  .menuIcon {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 24px;
  }
}
@media screen and (max-width: 859px) {
  .header {
    display: flex;
    align-items: center;
    padding-left: 200px;
    background-color: #fff;
    box-shadow: 0px 2px 5px 0px rgba(199, 199, 199, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    & > .el-menu--horizontal {
      flex: 1;
    }
    .title {
      position: absolute;
      left: 0;
      top: 5px;
      width: 170px;
    }
    .el-menu-vertical,
    .el-menu-collapse {
      left: -300px;
    }
    .menuIcon {
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 24px;
    }
  }
}
</style>
