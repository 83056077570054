<script setup name="Logo">
import imgLogo from '@/assets/images/layout/logo.png'

import useSettingsStore from '@/store/modules/settings'
const settingsStore = useSettingsStore()

defineProps({
  showLogo: {
    type: Boolean,
    default: true,
  },
  showTitle: {
    type: Boolean,
    default: true,
  },
})

const title = ref(window._env.TITLE)
const logo = ref(imgLogo)

const to = computed(() => {
  let rtn = {}
  if (settingsStore.dashboard.enable) {
    rtn.name = 'dashboard'
  }
  return rtn
})
</script>

<template>
  <router-link
    to="/"
    class="title"
    :class="{ 'is-link': settingsStore.dashboard.enable }"
    :title="title"
  >
    <img v-if="showLogo" :src="logo" class="logo" />
  </router-link>
</template>

<style lang="scss" scoped>
.title {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: inherit;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--g-sidebar-logo-height);
  text-align: center;
  overflow: hidden;
  text-decoration: none;
  &.is-link {
    cursor: pointer;
  }
  .logo {
    width: 150px;
    height: 30px;
    object-fit: contain;
    & + span {
      margin-left: 10px;
    }
  }
  span {
    display: block;
    font-weight: bold;
    color: #fff;
    @include text-overflow;
  }
}
@media screen and (max-width: 859px) {
  .title {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: inherit;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--g-sidebar-logo-height);
    text-align: center;
    overflow: hidden;
    text-decoration: none;
    &.is-link {
      cursor: pointer;
    }
    .logo {
      width: 140px;
      height: 30px;
      object-fit: contain;
      & + span {
        margin-left: 10px;
      }
    }
    span {
      display: block;
      font-weight: bold;
      color: #fff;
      @include text-overflow;
    }
  }
}
</style>
