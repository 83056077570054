import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import hotkeys from 'hotkeys-js'
import mitt from 'mitt'

export default function globalProperties(app) {
  // 鉴权
  dayjs.locale('zh-cn')
  app.config.globalProperties.$dayjs = dayjs
  app.config.globalProperties.$hotkeys = hotkeys
  app.config.globalProperties.$eventBus = mitt()
}
